export const DisplayName = "Policybazaar";
export const From = "careqa@policybazaar.com";
export const VERSION = "2.10.1";
export const SubIssueIDList = [
  72180, 72221, 72232, 72391, 72392, 72393, 73255, 73269, 90913, 90917, 90921,
  90929, 90935, 90940, 90947, 90954, 90961, 90991, 91002, 91013, 91115, 91134,
  91151, 91175, 91199, 91223, 91856, 91862, 91868, 91874, 92043, 92057, 92071,
  92084, 92125, 92138, 92143, 92147, 92149, 92157, 92170, 92183, 92186, 92198,
  92202, 92208, 92223, 92228, 92234, 92249, 92254, 92260, 92275, 92281, 92292,
  92316, 92327, 92351, 92362, 92386, 92397,
];

export const TERM = 7;
export const HEALTH = 2;
export const LOJ = 177;

export const INVESTMENT = 115;
export const CANCER = 138;

export const TWO_WHEELER = 114;
export const COMMERCIAL = 139;
export const HOME = 101;
export const INTERNATIONAL_CAR = 148;
export const INTERNATIONAL_HEALTH = 151;
export const INTERNATIONAL_TERM = 155;
export const INTERNATIONAL_TWO_WHEELER = 163;
export const INTERNATIONAL_TRAVEL = 150;

export const PERSONAL_ACCIDENT = 118;
export const CRITICAL_ILLNESS = 106;

export const TRAVEL = 3;
export const POSPCAR = 186;
export const POSPTW = 187;
export const POSPCOMMERCIAL = 188;
export const POSPPA = 189;
export const POSPHOME = 194;
export const STU = 130;
export const HEALTH_ON_LIFE = 216;
export const POSPHEALTH = 190;

export const CAR = 117;
export const SME = 131;

export const FETCH_TEMPLATE_ON_TICKET_CHANGE = [ HEALTH ]

export const endorsementIssue = "changes required in policy details";

export const ProductWiseEndorsementIssueSubIssueMapping = () => {
  let obj = {
    [SME]: {
      'endorsement request': null
    }
  }
  let issueSubIssue1 = {
    'changes required in policy details': null,
    "renewal related query": ['make changes to policy before renewal']
  }
  isEndorsementEligible(null, null, null, true)?.filter(a => !(Object.keys(obj).map(p=> Number(p))).includes(a))?.map(b => {
    obj[b] = issueSubIssue1
  })
  return obj
}

export const POLICY_DISPATCH_PRODUCTS = [HEALTH, STU, CRITICAL_ILLNESS, POSPHEALTH]

export const ProductWiseDispatchRequestIssueSubIssueMapping = () => {
  let obj = {
    [HEALTH]: {
      'need policy copy or payment receipt': ['need policy hard copy'],
    },
    [STU]: {
      'need policy copy or payment receipt': ['need policy hard copy'],
    },
    [CRITICAL_ILLNESS]: {
      'need policy copy or payment receipt': ['need policy hard copy'],
    },
    [POSPHEALTH]: {
      'need policy copy or payment receipt': ['need policy hard copy'],
    },
  }
  return obj
}

export const ENDORSEMENT_ELIGIBLE_PRODUCT_OPEN_TICKET_OLD_VIEW = [SME]

export const sameIssueSubissue = [
  [86568, 86636],
  [94719, 95067],
  [94719, 95068],
  [94701, 94902],
  [94707, 94957],
  [94707, 94958],
  [94713, 95013],
  [94713, 95014],
  [94701, 94901],
  [90913, 92125],
  [90935, 91134],
  [90929, 91115],
  [90929, 92157],
  [90917, 92138],
  [90961, 92254],
  [90917, 91002],
  [90961, 91223],
  [90940, 91151],
  [90954, 91199],
  [90954, 92228],
  [90921, 91013],
  [90921, 92149],
  [90913, 90991],
  [90935, 92170],
  [90940, 92183],
  [90947, 91175],
  [90947, 92202],
  [91874, 92084],
  [91874, 92084],
  // [91874, 92386],
  // [91868, 92351],
  [91862, 92057],
  // [91862, 92316],
  [91868, 92071],
  [91868, 92071],
  [93226, 96638],
  [91856, 92043],
  [91856, 92043],
  [93208, 96526],
  [93220, 96600],
  [91862, 92057],
  [93220, 96601],
  [93214, 96564],
  [93362, 96934],
  [93344, 96822],
  [93362, 96933],
  [93226, 96637],
  [93208, 96527],
  [93214, 96563],
  [93356, 96896],
  [93356, 96897],
  [93350, 96860],
  [93276, 96674],
  [93276, 96675],
  [93294, 96785],
  [93282, 96711],
  [93288, 96748],
  [93344, 96823],
  [93294, 96786],
  [93350, 96859],
  [93282, 96712],
  [100011, 100014],
  [100045, 100046],
  [93288, 96749],
  [99811, 99814],
  [99978, 99981],
  [99839, 99840],
  [100204, 100207],
  [100094, 100095],
  [100094, 100097],
  [100045, 100048],
  [100149, 100150],
  [99811, 99812],
  [100011, 100012],
  [99978, 99979],
  [99839, 99842],
  [100204, 100205],
  [99866, 99867],
  [99866, 99868],
  [100149, 100152],
  [94651, 95474],
  [94633, 95309],
  [94645, 95420],
  [94633, 95308],
  [94639, 95364],
  [94651, 95475],
  [94639, 95365],
  [94645, 95421],
];

export const checkForSameIssueSubIssue = (val) => {
  if (
    val &&
    sameIssueSubissue.filter((i) => JSON.stringify(i) == JSON.stringify(val))
      .length > 0
  ) {
    return false;
  } else {
    return true;
  }
};

export const openTickets = [1, 2, 5];


export const customerReplyFilters = ["myaccount", "endorsementmyaccount"];
export const emailFilters = [
  "care",
  "communication",
  "rmemails",
  "renewal",
  "info",
  "travel",
  "app",
];
export const pbReplyFilters = ["pbreply"];
export const esclationFilters = ["escalation", "irda", "social"];
export const ASSIGNMENT = "Assignment";
export const STATUS_SUBSTATUS = ["Status", "Sub Status"];
export const ISSUE_SUBISSUE = ["Issue Type", "SubIssue Type"];
export const FOLLOWUP = "FollowUpDate";
export const INSURER_STATUS = "Insurer Status";
export const LEADID = "LeadId";
export const EMAIL_FROM_CUSTOMER = "Email from Customer";
export const MY_ACCOUNT = "myaccount";
export const TICKET_SOURCE_CARE = "care";

export const SourceAllowedForReplyThread = [
  "care",
  "myaccount",
  "communication",
  "rmemails",
  // "smerm"
];
export const isReplyThreadEligible = (source) => {
  if (
    source &&
    SourceAllowedForReplyThread.indexOf(source.toLowerCase()) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const ProductsAllowedForTicketComm = [114, 139, 117, 131, 2, 216, TERM, INVESTMENT,130, TRAVEL];
export const ProductsForNewTicketComm = [2, 216, TERM, INVESTMENT,130, TRAVEL];
// export const ProductsAllowedForTicketComm = [117, 131];
export const ProductsAllowedForCallPopup = [185, 186, 187, 188, 189];
// export const ProductsAllowedForDefaultTemplate = [185,186,187,188,189];
export const isCommunicationEligible = (productId) => {
  if (
    productId &&
    ProductsAllowedForTicketComm.map((i) => i).indexOf(productId) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const isEligibleForTicketNewComm = (productId) => {
  if (
    productId &&
    ProductsForNewTicketComm.includes(productId)
  ) {
    return true;
  } else {
    return false;
  }
};

// 0 is for ticket system
// 1 is for wecare
export const isCallingPopEligible = (productId) => {
  if (
    productId &&
    ProductsAllowedForCallPopup.map((i) => i).indexOf(productId) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const envUrl = () => {
  let url = window.location.href;
  if (url.toLowerCase().includes("localhost")) {
    //  bmsv2Token = ""
    return "DEV";
    //  return "PROD"
  } else if (url.toLowerCase().includes("ticketqa.policybazaar.com")) {
    return "DEV";
  } else if (url.toLowerCase().includes("ticketbuild.policybazaar.com")) {
    return "PREPROD";
  } else if (url.toLowerCase().includes("https://ticket.policybazaar.com/")) {
    return "PROD";
  } else if (url.toLowerCase().includes("https://wecare.policybazaar.com/")) {
    return "PROD";
  }
  return "";
};

// const ProductsAllowedForEndorsement = [];
export const isEndorsementEligible = (productId, supplierId, subProduct, returnEndorsementProducts) => {
  let ProductsAllowedForEndorsement;
  if (envUrl() == "DEV") {
    ProductsAllowedForEndorsement = [114, 117, 139, 186, 187, 188, 190, 118, 2, SME,130];
    
  }
  if (envUrl() == "PREPROD") {
    ProductsAllowedForEndorsement = [117, 114, 118, 2, POSPCOMMERCIAL, SME,130];
  }
  if (envUrl() == "PROD") {
    ProductsAllowedForEndorsement = [117, 114, 118, 2, POSPCOMMERCIAL, SME,130];
  }
  let ProductSupplierList = {
    [HEALTH]: [44, 54, 18],
    // [CRITICAL_ILLNESS]:[18,40],
    [STU]:[44, 54, 18],
    // [POSPHEALTH]:[44, 54, 18],
  }
  let ProductSubProductMaster = {
    [SME] : [13, 5, 6]
  }

  if(returnEndorsementProducts){ return ProductsAllowedForEndorsement} //for mapping
  if (
    productId &&
    ProductsAllowedForEndorsement.map((i) => i).indexOf(productId) > -1
  ) {
    if(supplierId && ProductSupplierList[productId]){
      if(ProductSupplierList[productId].includes(supplierId)){
        return true
      }
      else return false
    }
    else if(subProduct && ProductSubProductMaster[productId]){
      if(ProductSubProductMaster[productId].includes(subProduct)){
        return true
      }
      else return false
    }
    else return true
  } else {
    return false;
  }
};


const ProductsAllowedForResetRefund = [115, 7];
export const isResetRefundEligible = (productId) => {
  if (
    productId &&
    ProductsAllowedForResetRefund.map((i) => i).indexOf(productId) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

const ProductsAllowedForCustomerPendancy = [186, 187, 188, 189, 190];
export const isCustomerPendancyEligible = (productId) => {
  if (
    productId &&
    ProductsAllowedForCustomerPendancy.map((i) => i).indexOf(productId) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

// export const isDefaultTemplateAvailable = (productId) => {
//   if(productId && ProductsAllowedForCallPopup.map(i => i).indexOf(productId) > -1){
//     return true
//   }
//   else{
//     return false
//   }
// }

export const SubSourceListForEscalation = [
  "Twitter",
  "Facebook",
  "Instagram",
  "LinkedIn",
  "Playstore",
  "Appstore",
  "Youtube",
  "Google My Business",
  "AskPB",
  "Other Forum",
];

export const isUserAllowedToEscalate = (userid) => {
  let UsersAllowedToEscalate;
  if (envUrl() == "DEV") {
    // ProductsAllowedForEndorsement = [114, 117, 139, 186, 187, 188];
    UsersAllowedToEscalate = [8554, 90435, 8502, 90555,2741,90825,8556,8394,90431];
  }
  if (envUrl() == "PROD") {
    UsersAllowedToEscalate = [
      37453, 38497, 31544, 24929, 27753, 35808, 37754, 41592, 40018, 58006,
    ];
  }
  if (envUrl() == "PREPROD") {
    UsersAllowedToEscalate = [
      37453, 38497, 31544, 24929, 27753, 35808, 37754, 41592, 40018, 58006,
    ];
  }
  if (userid && UsersAllowedToEscalate.map((i) => i).indexOf(userid) > -1) {
    return true;
  } else {
    return false;
  }
};

export const handleCancellationSelection = (IssueCode) => {
  if (IssueCode && IssueCode === 'HELPINCANCELLATIONOFTHEPOLICY') {
    return true;
  }
  else return false;
};

export const isAllowedBlockComm = (userid) => {
  // let UsersAllowedForBlockComm = [25519, 18244, 30734, 30827, 37453, 3246, 22236];
  let UsersAllowedForBlockComm = [3246, 22236, 7623, 30097, 37453,90825,8556,2741,7623];

  if (userid && UsersAllowedForBlockComm.map((i) => i).indexOf(userid) > -1) {
    return true;
  } else {
    return false;
  }
};

// const IssueIdForClaims = [
//   101506, 101507, 101508, 101509, 101510, 101511, 101512, 101513, 101514,
//   101515, 101516, 101517, 101518, 101519, 101520, 101521, 101522, 101523,
//   101524, 101525, 101526, 101527, 101528, 101529, 101530, 101531, 101532,
//   101533, 101534, 101535, 101536, 101537, 101538, 101539, 101540, 101541,
//   101542, 101543,
// ];
// const IssueIdForClaims = [
//   100869, 100888, 100870, 100889, 100871, 100890, 100872, 100891, 100873,
//   100892, 100874, 100893, 100875, 100894, 100876, 100895, 100878, 100897,
//   100879, 100898, 100899, 100881, 100882, 100901, 100902, 100884, 100903,
//   100885, 100904, 100886, 100905, 100877, 100896, 100887, 100906, 100880,
//   100900, 100883,
// ];

export const isIssueAllowedToClaims = (issue) => {
  let IssueIdForClaims;
  if (envUrl() == "DEV") {
    // ProductsAllowedForEndorsement = [114, 117, 139, 186, 187, 188];
    IssueIdForClaims = [
      101506, 101507, 101508, 101509, 101510, 101511, 101512, 101513, 101514,
      101515, 101516, 101517, 101518, 101519, 101520, 101521, 101522, 101523,
      101524, 101525, 101526, 101527, 101528, 101529, 101530, 101531, 101532,
      101533, 101534, 101535, 101536, 101537, 101538, 101539, 101540, 101541,
      101542, 101543,
    ];
  }
  if (envUrl() == "PREPROD") {
    IssueIdForClaims = [
      100869, 100888, 100870, 100889, 100871, 100890, 100872, 100891, 100873,
      100892, 100874, 100893, 100875, 100894, 100876, 100895, 100878, 100897,
      100879, 100898, 100899, 100881, 100882, 100901, 100902, 100884, 100903,
      100885, 100904, 100886, 100905, 100877, 100896, 100887, 100906, 100880,
      100900, 100883,
    ];
  }
  if (envUrl() == "PROD") {
    IssueIdForClaims = [
      100869, 100888, 100870, 100889, 100871, 100890, 100872, 100891, 100873,
      100892, 100874, 100893, 100875, 100894, 100876, 100895, 100878, 100897,
      100879, 100898, 100899, 100881, 100882, 100901, 100902, 100884, 100903,
      100885, 100904, 100886, 100905, 100877, 100896, 100887, 100906, 100880,
      100900, 100883,
    ];
  }
  if (issue && IssueIdForClaims.map((i) => i).indexOf(issue) > -1) {
    return true;
  } else {
    return false;
  }
};


export const showPriorityFlag = (productId) => {
  let products = [186, 187, 188, 194, 190, HEALTH, CRITICAL_ILLNESS, STU];
  if (productId && products.indexOf(productId) > -1) {
    return true;
  } else {
    return false;
  }
};

export const ShowPartnerLevel = (productId) => {
  let POSPproducts = [186, 187, 188, 189, 190];
  if (productId && POSPproducts.indexOf(productId) > -1) {
    return true;
  } else {
    return false;
  }
};
// Product Health and STU Refund Cancellation
export const ProductEligibleForRefund = (productId, supplierId) => {
  let products = [];
  let suppliers = [];
  if (envUrl() == "DEV") {
    products = [2, 106, 130];
    suppliers = [44, 54, 18, 17,56];
  }
  if (envUrl() == "PREPROD") {
    products = [2, 130];
    suppliers = [44,18,54];
  }
  if (envUrl() == "PROD") {
    products = [2, 130];
    suppliers = [44,18,54];
  }
  if (productId && products.indexOf(productId) > -1) {
    if (supplierId && suppliers.indexOf(supplierId) > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const acronym = (str) => {
  let str1 = str;
  let acronym = str1
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
  let initial = acronym[0];
  let last = acronym.length - 1 == 0 ? "" : acronym[acronym.length - 1];
  let ShortName = initial + last;
  return ShortName.toUpperCase();
};

export const getFollowUpdateTimeInProperFormate = (time) => {
  if (time) {
    const timeArr = time.split(" ");
    const newTimeArr = [];
    timeArr.forEach((item) => {
      if (item) {
        newTimeArr.push(item);
      }
    });
    return `${
      newTimeArr[1].length === 1 ? "0" + newTimeArr[1] : newTimeArr[1]
    } ${newTimeArr[0]} ${newTimeArr[2]} ${
      newTimeArr.length === 4 ? newTimeArr[3] : ""
    }`;
  }
  return "";
};
// Agents allowed to create PB manual tickets for Term and Investment

export const UsersAllowedForTicketCreation = (userID) => {
  let users = [];
  if (envUrl() == "DEV") {
    users = [8556, 90539, 8240, 90555];
  }
  if (envUrl() == "PREPROD") {
    users = [
      7623, 31792, 40018, 19350, 24929, 27753, 35808, 39583, 8264, 13829, 41592,
      11584, 28389, 19418, 36411,
    ];
  }
  if (envUrl() == "PROD") {
    users = [
      7623, 31792, 40018, 19350, 24929, 27753, 35808, 39583, 8264, 13829, 41592,
      11584, 28389, 19418, 36411,
    ];
  }
  if (users && users.indexOf(userID) > -1) {
    return true;
  } else {
    return false;
  }
};

//show pg refund dialog on ticket load

export const showPGRefundDialog = (
  ticketSource,
  ISREFUNDED,
  productId,
  ticketCommData
) => {
  let refund = ISREFUNDED == 1 ? true : false;
  let productList = [7, 115];
  if (productList.indexOf(productId) > -1 && ticketCommData.length > 0) {
    let communications = ticketCommData.filter((comm) =>
      comm.EventType
        ? comm.EventType.toLowerCase().includes(
            EMAIL_FROM_CUSTOMER.toLowerCase()
          ) ||
          [
            "rmemails",
            "care",
            "communication",
            "communications",
            "social",
            "topmanagement",
            "whatsapp",
            "myaccount"
          ].indexOf(comm.TicketSource?.toLowerCase()) > -1
          ? true
          : false
        : false
    );
    if (
      communications &&
      Array.isArray(communications) &&
      communications.length > 0 &&
      refund
    ) {
      return true;
    } else return false;
  } else return false;
};

// Name formatted for tickets

export const getFormatedName = (name) => {
  if (name.includes("(")) {
    const nameArr = name.split("(");
    return nameArr[0] + " (" + nameArr[1];
  } else {
    return name;
  }
};

export const ahcIssue = 'annual health check-up and assessment';
export const ahcSubissue = 'manage annual health checkup request';
export const AHC_SUPPLIERS = [18, 54, 44];

export const showAHCId = (productId) => {
  return true;
  let products = [117, 114, 2, 130, 216]; //216 -Health on life
  if (productId && products.indexOf(productId) > -1) {
    return true;
  } else {
    return false;
  }
};

export const SHOW_AHC_IN_MORE_OPTIONS = [HEALTH, HEALTH_ON_LIFE]

export const RESOLVE_TICKET_DIRECTLY = [131, POSPCAR, POSPTW, POSPCOMMERCIAL, POSPPA, POSPHEALTH, POSPHOME];

// INSURER_USERID_ALLOWED_FOR_TICKETS for create tickets
export const INSURER_USERID_ALLOWED_FOR_TICKETS = [14,899,921,922,935,936,937,987, 1027,1028,1029,1030,1113,1114,1115,1116,1117,1118,1119,1120,1121,1122,1123,1124,1125];
export const DISABLE_DEFAULT_TEMPLATE_LOADING = [ 131, 117, 2, 7, 115 ]

export const SUMMARY_DASHBOARD_TYPE = 1;
export const TAT_SUMMARY_DASHBOARD_TYPE = 3;

export const AGENT_SIMPLE_SEARCH_TYPE = 7;
export const AGENT_ADVANCE_SEARCH_TYPE = 1;

export const ADMIN_SIMPLE_SEARCH_TYPE = 12;
export const ADMIN_ADVANCE_SEARCH_TYPE = 13;

export const ED_SIMPLE_SEARCH_TYPE = 12;
export const ED_ADVANCE_SEARCH_TYPE = 13;

export const USERID_ALLOWERD_TO_MANAGE_USERS = (env) => {

  let IDs = {
    'DEV': [
      90539, //Hitesh
      90555, //Ajay Dubey
      2741, //Asmit
      8556, //Gaurav
      8394, //Ayush
      8393, //Reman
      5737,
      8375, //Karthik
      40585,
      32447, //Mukul
      2346,
      2351,
      90587, //Vishal Luthra
      90825, //Garv kholi
      90031, //Neeraj SME
      90880, //Achintya,
      90360, //Anant
    ],
    'PREPROD': [
      39583, //Hitesh
      40018, //Ajay
      33125, //Ayush
      37754, //Mukul
      7623, // Asmit
      38537, // Anuj
      15064, //Reman
      5141, //Lalan
      40585, //Vishal Luthra
      31792, //GB
      8395, //Arun
      5737,
      8375,
      13886,
      10200, //Achintya
      33984, //Anant
      8240, //Naveen
      6738,//Aman Ahuja
    ],
    'PROD': [
      39583, //Hitesh
      40018, //Ajay
      33125, //Ayush
      37754, //Mukul
      7623, // Asmit
      38537, // Anuj
      15064, //Reman
      5141, //Lalan
      40585, //Vishal Luthra
      31792, //GB
      8395, //Arun
      5737,
      8375,
      13886,
      10200, //Achintya
      33984, //Anant
      8240, //Naveen
      6738,//Aman Ahuja
    ]
  }
  if(env){
    return IDs[env]
  }
  else return []

}
export const USERID_ALLOWED_FOR_APP_LOG = (env) => {

  let IDs = {
    'DEV': [
      90539, //Hitesh
      90555, //Ajay Dubey
      2741, //Asmit
      8556, //Gaurav
      8394, //Ayush
      8393, //Reman
      5737,
      8375, //Karthik
      40585,
      32447, //Mukul
      2346, //lalan
      2351, //lalan
      90587, //Vishal Luthra
      90431, //Anuj wani
      90825, //Garv kholi
      90845, //Ashwani
      90031, //Neeraj SME
      
    ],
    'PREPROD': [
      39583, //Hitesh
      40018, //Ajay
      33125, //Ayush
      37754, //Mukul
      7623, // Asmit
      38537, // Anuj
      15064, //Reman
      5141, //Lalan
      40585, //Vishal Luthra
      31792, //GB
    ],
    'PROD': [
      39583, //Hitesh
      40018, //Ajay
      33125, //Ayush
      37754, //Mukul
      7623, // Asmit
      38537, // Anuj
      15064, //Reman
      5141, //Lalan
      40585, //Vishal Luthra
      31792, //GB
      10200, //Achintya Goel
      39890, //Rohit BMS
    ]
  }
  return IDs[env]

}

export const PB_ADMIN = 10;
export const PB_AGENT = 11;
export const ADMIN_SUMMARY_DASHBOARD_TYPE_V2 = 1;
export const ADMIN_TAT_SUMMARY_DASHBOARD_TYPE_V2 = 3;

export const BLACKLIST_EMAILS = ['noreply@policybazaar.com','no-reply@policybazaar.com', 'care@policybazaar.com', 'communications@policybazaar.com'];

export const COMMON_CK_EDITOR_CONFIG = {
  width: "auto",
  toolbarGroups: [
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
    },

    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },

    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    { name: "others", groups: ["others"] },
  ],
  removeButtons:
    "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
  fontSize_sizes: "16/16px;24/24px;48/48px;",
  font_names:
    "Arial/Arial, Helvetica, sans-serif;" +
    "Times New Roman/Times New Roman, Times, serif;" +
    "Verdana",
  format_tags: "p;h1;h2;h3;pre;div",
  removeDialogTabs: "image:advanced;link:advanced",
}

export const GRAMMARLY_CLIENT_ID = 'client_8kHNRPFz9DyjFB3yt9TXRp';
export const ENABLE_CUSTOMER_PENDENCY_DIALOG = [HEALTH, TWO_WHEELER, CAR, TRAVEL, TERM, INVESTMENT, CRITICAL_ILLNESS, PERSONAL_ACCIDENT, STU, CANCER, COMMERCIAL, 216, POSPHEALTH];
export const BYPASS_REOPEN_TICKET_FOR_CUSTOMER_PENDENCY_DIALOG = [HEALTH, STU, CAR, TWO_WHEELER];
export const OPEN_ENDORSEMENT_IN_CUSTOMER_PENDENCY_SUBSTATUS = [SME]
export const OPEN_ENDORSEMENTWINDOW_DIRECTLY = [CAR, HEALTH]; 

export const WECARE_ENDORSEMENT_PRODUCT_SUPPLIERS = (product, supplier) =>{
  let productSupplierObj = {
    [HEALTH]: [44, 54, 18],
    [STU]:[44, 54, 18],
    [CAR] : [12],
    [TWO_WHEELER] : [107]
  }
  console.log(productSupplierObj[product])
  if(productSupplierObj[product] && productSupplierObj[product].includes(supplier)){
    return true
  }
  else return false

}
export const USERID_ALLOWED_FOR_SKIP_REPORT_ONELEAD = (env) => {
  let IDs = {
    'DEV': [
      90539, //Hitesh
      90555, //Ajay Dubey
      2741, //Asmit
      8556, //Gaurav
      8394, //Ayush
      8393, //Reman
      90587, //Vishal Luthra
      91114, //Car TAP Admin
    ],
    'PREPROD': [
      39583, //Hitesh
      40018, //Ajay
      33125, //Ayush
      7623, // Asmit
      5141, //Lalan
      15064, //Reman
      40585, //Vishal Luthra
      31792, //GB
      8240, //Naveen
      14357, //Dhiraj panday
      15794, //Amit Goswam
      14358, //Utsav Bhardwaj
      5737, //Ashish Gupta
      29820, //Vipin
      10200, // Achintya
      19350, //HUNNY
      19754, //ZOHAIB
      25494, //Anand Vishwakarma
      32119, //Mayank Chandwani
      32907, //Khushdeep Sharma
      33993, //Avinash Mishra
      39655, //Joninder Singh
      44048, //Sandeep Sharma 2
      44184, //Raghav Ahuja
      46409, //Nitin Kumar Saxena
    ],
    'PROD': [
      39583, //Hitesh
      40018, //Ajay
      33125, //Ayush
      7623, // Asmit
      5141, //Lalan
      15064, //Reman
      40585, //Vishal Luthra
      31792, //GB
      8240, //Naveen
      14357, //Dhiraj panday
      15794, //Amit Goswam
      14358, //Utsav Bhardwaj
      5737, //Ashish Gupta
      29820, //Vipin
      10200, // Achintya
      19350, //HUNNY
      19754, //ZOHAIB
      25494, //Anand Vishwakarma
      32119, //Mayank Chandwani
      32907, //Khushdeep Sharma
      33993, //Avinash Mishra
      39655, //Joninder Singh
      44048, //Sandeep Sharma 2
      44184, //Raghav Ahuja
      46409, //Nitin Kumar Saxena

    ]
  }
  return IDs[env]
}


export const quickSearchFieldLength = {
  max: 200,
  min: 5
}
export const RESTRICT_MANUAL_TICKET_CLOSURE = (product, supplier) =>{
  let productSupplierObj = {
    [HEALTH] : [44,54]
  }
  if(productSupplierObj[product] && productSupplierObj[product].includes(supplier)){
    return true
  }
  else return false
}

export const AllowedToRaiseEndorsementAtLogin = (productId, supplierId, subProduct) => {
  let ProductSupplierList = {
    [HEALTH]: [44, 54, 18],
    [STU] : [44, 54, 18],
    [CRITICAL_ILLNESS] : [40,18],
    [POSPHEALTH] : [44, 54, 18]
  }
  let ProductSubProductMaster = {
    [SME]: [13, 5, 6]
  }
  if (ProductSupplierList[productId]?.includes(supplierId)) {
    return true
  }
  else if (subProduct && ProductSubProductMaster[productId]?.includes(subProduct)) {
    return true
  }
  else return false
}

export const RAISE_TO_BRANCH_PRODUCTS = [186,187,188];

export const DISABLED_SEND_TO_INSURER_PRDOUCTS_SUPPLIER =
(p, s) =>{
  let master = {
    [HEALTH] : [44],
    [STU] : [44],
    [CRITICAL_ILLNESS] : [44],
    [POSPHEALTH] : [44]
  }
  if(s && p){
    return master[p]?.includes(s) ? true : false
  }
  else return true
}

export const GW_LITE_PRODUCTS = [CAR]

export const INSURER_ESCALATION_BUCKET_PRODUCT_SUPPLIER_MASTER = {
  [HEALTH]: [3, 66],
  [TRAVEL]: [66, 6],
  [CRITICAL_ILLNESS]: [34],
  [TWO_WHEELER]: [194],
  [CAR]: [3],
  [POSPCAR]: [3],
  [POSPTW]: [194],
  [POSPCOMMERCIAL]: [3],
  [POSPHEALTH]: [3, 66]
}